import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../context";
import { api } from "../config";

const useSessionManagement = () => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const [sessionDialogOpen, setSessionDialogOpen] = useState(false);
  const [sessionContinued, setSessionContinued] = useState(false);

  const refreshToken = async () => {
    try {
      const response = await api.post("/auth/refresh-token", {
        token: window.localStorage.getItem("alex-med-token"),
      });
      const newToken = response.data.token;
      window.localStorage.setItem("alex-med-token", newToken);
      return newToken;
    } catch (error) {
      console.log("Error refreshing token:", error);
      throw error;
    }
  };

  const handleSessionContinue = async () => {
    setSessionDialogOpen(false);
    setSessionContinued(true);
    try {
      await refreshToken();
      toast.success("Session extended successfully!");
      setSessionContinued(false);
    } catch (error) {
      toast.error("Failed to extend session. Please log in again.");
      setLoggedIn(false);
      navigate("/login");
    } finally {
      setSessionContinued(false);
    }
  };

  const handleSessionLogout = () => {
    window.localStorage.removeItem("alex-med-token");
    setSessionDialogOpen(false);
    setLoggedIn(false);
    navigate("/login");
  };

  useEffect(() => {
    const checkSession = () => {
      const token = window.localStorage.getItem("alex-med-token");
      if (token) {
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        const tokenExpirationTime = tokenPayload.exp * 1000;
        const warningTime = tokenExpirationTime - 5 * 60 * 1000; // 5 minutes before expiration

        if (
          Date.now() >= warningTime &&
          Date.now() <= tokenExpirationTime &&
          !sessionContinued
        ) {
          setSessionDialogOpen(true);
        } else if (Date.now() >= tokenExpirationTime && !sessionContinued) {
          handleSessionLogout();
        }
      }
    };

    const intervalId = setInterval(checkSession, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return {
    sessionDialogOpen,
    handleSessionContinue,
    handleSessionLogout,
  };
};

export default useSessionManagement;
