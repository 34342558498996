import React, { useState } from "react";
import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormGroup,
  FormControl,
  RadioGroup,
  Chip,
  Input,
} from "@mui/material";
import { toast } from "react-toastify";
import { CHRONIC_PAINS } from "../../config";

const FollowupHPI = ({
  dateOfPainConsultNote,
  followupHPI,
  pastFollowupNotes,
  setFollowupHPI,
  initialHPI,
  pains,
  setPains,
  selectedPainIndex,
  setSelectedPainIndex,
  selectedPainKey,
  setSelectedPainKey,
  physicalExams,
  setPhysicalExams,
  getDefaultPhysicalExam,
}) => {
  const [content, setContent] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setContent(followupHPI);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleUpdate = () => {
    setFollowupHPI(content);
    setOpen(false);
  };

  const addPain = (painKey) => {
    // Not custom pain and the painKey already exists
    if (
      painKey !== "customPain" &&
      pains.map((pain) => pain["key"]).indexOf(painKey) !== -1
    ) {
      toast.warn("That pain already selected!");
    } else {
      setSelectedPainKey(painKey);
      setSelectedPainIndex(pains.length);
      setPains([
        ...pains,
        {
          key: painKey,
          name: CHRONIC_PAINS[painKey],
          hpi: "",
        },
      ]);

      const newPhysicalExam = getDefaultPhysicalExam(painKey);
      setPhysicalExams([...physicalExams, newPhysicalExam]);
    }
  };

  const selectPain = (painIndex) => {
    setSelectedPainIndex(painIndex);
    setSelectedPainKey(pains[painIndex]["key"]);
  };

  const removePain = (index) => {
    const newPains = [...pains];
    newPains.splice(index, 1);
    setPains(newPains);
    setSelectedPainIndex(0);
    if (newPains.length) {
      setSelectedPainKey(newPains[0]["key"]);
    } else {
      setSelectedPainKey(null);
    }

    const newPhysicalExams = [...physicalExams];
    newPhysicalExams.splice(index, 1);
    setPhysicalExams(newPhysicalExams);
  };

  const handleCustomPainNameChange = (newName) => {
    const newPains = [...pains];
    newPains[selectedPainIndex] = {
      ...pains[selectedPainIndex],
      name: newName,
    };
    setPains(newPains);
  };

  return (
    <Stack direction="column">
      <Stack direction="row">
        <FormGroup
          style={{ width: "200px", minWidth: "200px", marginRight: "10px" }}
        >
          <FormControl>
            <RadioGroup>
              {Object.keys(CHRONIC_PAINS).map((pain, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => addPain(pain)}
                >
                  {CHRONIC_PAINS[pain]}
                </Button>
              ))}
            </RadioGroup>
            {selectedPainKey === "customPain" && (
              <Input
                placeholder="Pain"
                variant="outlined"
                style={{ margin: "10px 0" }}
                value={pains[selectedPainIndex]["name"]}
                onChange={(e) => handleCustomPainNameChange(e.target.value)}
              />
            )}
          </FormControl>
        </FormGroup>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h5" fontWeight="bold">
              Chief Complaint:
            </Typography>
            <Stack direction="column" spacing={1}>
              <Typography variant="h6">
                {pains.map((pain) => pain["name"]).join(", ")}
              </Typography>
              <Stack direction="row" spacing={1}>
                {pains &&
                  pains.map((pain, index) => (
                    <Chip
                      color={
                        selectedPainIndex === index ? "success" : "primary"
                      }
                      key={index}
                      label={pain["name"]}
                      onDelete={() => removePain(index)}
                      onClick={() => {
                        selectPain(index);
                      }}
                    />
                  ))}
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" style={{ marginTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold">
              HPI:
            </Typography>
            <Stack direction="column">
              <Typography variant="h6" fontWeight="bold">
                Follow-up HPI:
              </Typography>
              <Stack direction="row">
                <FormGroup
                  style={{
                    minWidth: "250px",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Button variant="contained" onClick={handleOpen}>
                    Edit
                  </Button>
                </FormGroup>
                <Stack direction="column">
                  {followupHPI.split("\n").map((item, index) => (
                    <Typography variant="h6" key={index}>
                      {item}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            </Stack>
            {pastFollowupNotes &&
              pastFollowupNotes.map((note) => (
                <Stack
                  direction="column"
                  style={{ marginTop: "20px" }}
                  key={note["_id"]}
                >
                  <Typography variant="h6" fontWeight="bold">
                    Followup HPI ({note["dateOfService"]}):
                  </Typography>
                  {note["followupHPI"].split("\n").map((item, index) => (
                    <Typography variant="h6" key={index}>
                      {item}
                    </Typography>
                  ))}
                </Stack>
              ))}
            <Stack direction="column" style={{ marginTop: "20px" }}>
              <Typography variant="h6" fontWeight="bold">
                Initial HPI ({dateOfPainConsultNote}):
              </Typography>
              {initialHPI &&
                initialHPI.split("\n").map((item, index) => (
                  <Typography variant="h6" key={index}>
                    {item}
                  </Typography>
                ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle>Edit Follow Up HPI</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Content"
            multiline
            rows={15}
            fullWidth
            variant="standard"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>OK</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default FollowupHPI;
