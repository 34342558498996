import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const SessionExpirationDialog = ({ open, onContinue, onLogout }) => {
  const handleModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onLogout();
    }
  };
  return (
    <Dialog open={open} onClose={handleModalClose}>
      <DialogTitle>Session Expiration Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session is about to expire. Do you want to continue your session?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogout} color="primary">
          Logout
        </Button>
        <Button onClick={onContinue} color="primary" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpirationDialog;
