import React, { useContext, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import PainConsultNote from "./pages/pain_consult_note";
import ProcedureNote from "./pages/procedure_note";
import FollowupNote from "./pages/followup_note";
import Patients from "./pages/patients";
import Patient from "./pages/patient";
import Appointments from "./pages/appointments";
import Users from "./pages/users";
import Billing from "./pages/billing";
import ChangePassword from "./pages/change_password";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgot_password";
import ResetPassword from "./pages/reset_password";
import ConsentForm from "./pages/consentForm";
import HipaaForm from "./pages/hipaaForm";
import { Context } from "./context";
import { api, secureApi } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SessionExpirationDialog from "./components/SessionExpirationDialog";
import useSessionManagement from "./hooks/useSessionManagement";

function App() {
  const { loggedIn, setLoggedIn, setUser, setPlans, setAuthorizations } =
    useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const { sessionDialogOpen, handleSessionContinue, handleSessionLogout } =
    useSessionManagement();

  useEffect(() => {
    const authToken = window.localStorage.getItem("alex-med-token");
    if (authToken) {
      api
        .post(`/auth/token-validate`, {
          token: authToken,
        })
        .then((response) => {
          const { decoded_token, token } = response.data;
          window.localStorage.setItem("alex-med-token", token);
          setUser(decoded_token);
          setLoggedIn(true);
        })
        .catch(() => {
          setLoggedIn(false);
          if (
            location.pathname !== "/forgot-password" &&
            location.pathname !== "/reset-password" &&
            location.pathname !== "/verify"
          ) {
            navigate("/login");
          }
        });
    } else {
      setLoggedIn(false);
      if (
        location.pathname !== "/forgot-password" &&
        location.pathname !== "/reset-password" &&
        location.pathname !== "/verify"
      ) {
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/plans`)
        .then((response) => {
          const { plans } = response.data;
          setPlans(plans);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });

      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/authorizations`)
        .then((response) => {
          const { authorizations } = response.data;
          setAuthorizations(authorizations);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  }, [loggedIn]);

  return (
    <>
      <Routes>
        {loggedIn && (
          <>
            <Route path="/" element={<Patients />} />
            <Route path="/login" element={<Navigate to="/" replace />} />
            <Route path="/patient" element={<Patient />} />
            <Route path="/patient/:patient_id" element={<Patient />} />
            <Route
              path="/pain-consult-note"
              element={<PainConsultNote is_telehealth_consult_note={false} />}
            />
            <Route
              path="/procedure-note/:procedure_note_id"
              element={<ProcedureNote />}
            />
            <Route path="/procedure-note" element={<ProcedureNote />} />
            <Route
              path="/telehealth-consult-note"
              element={<PainConsultNote is_telehealth_consult_note={true} />}
            />
            <Route
              path="/followup-note/:followup_note_id"
              element={<FollowupNote />}
            />
            <Route path="/followup-note" element={<FollowupNote />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/users" element={<Users />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
              path="/consent-form/:patient_id/:index?"
              element={<ConsentForm />}
            />
            <Route path="/hipaa-form/:patient_id" element={<HipaaForm />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
        {!loggedIn && (
          <>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/reset-password"
              element={<ResetPassword item="reset" />}
            />
            <Route path="/verify" element={<ResetPassword item="verify" />} />
            {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
          </>
        )}
      </Routes>
      {loggedIn && (
        <SessionExpirationDialog
          open={sessionDialogOpen}
          onContinue={handleSessionContinue}
          onLogout={handleSessionLogout}
        />
      )}
    </>
  );
}

export default App;
